<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="900"
    v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot/>
      </div>
    </template>
    <template>
      <div class="salary-history position-relative">
        <div class="pa-4 bg-white">
          <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="-1"
            @click:row="(item) => {this.selectedCell = item}"
          >
            <template v-slot:item.itemId="{ item }">
              <template v-if="item.__typename === 'ProductPlanItemsCountLog'">
                {{ item.orderProductPlan.id }}
              </template>
              <template v-else>
                {{ item.orderService.id }}
              </template>
            </template>
            <template v-slot:item.productPlanName="{ item }">
              <template v-if="item.__typename === 'ProductPlanItemsCountLog'">
                {{ item.orderProductPlan.name }}
              </template>
              <template v-else>
                {{ item.orderService.name }}
              </template>
            </template>
            <template v-slot:item.type="{ item }">
              {{ item.__typename === 'ProductPlanItemsCountLog' ? 'Product' : 'Service' }}
            </template>
            <template v-slot:item.count="{ item }">
              {{ item.memorizedCount }} /
              <template v-if="item.__typename === 'ProductPlanItemsCountLog'">
                {{ item.orderProductPlan.count }}
              </template>
              <template v-else>
                {{ item.orderService.count }}
              </template>
            </template>
            <template v-slot:item.status="{ item }">
              <span
                style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                class="rounded-circle"
                :style="color(item.status)"
              />
              <span style="max-width: 80px">
          {{ getStatus(item.status) }}
        </span>
            </template>
            <template v-slot:item.income="{ item }">
              {{ Math.round(item.incomeRub) }}₽
            </template>
            <template v-slot:item.changedAt="{ item }">
              <vc-date-picker
                locale="en"
                class="inline-block h-full"
                :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
                :popover="{ visibility: 'click' }"
                :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                mode="date"
                @input="changeDate(item.changedAt)"
                :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                v-model="item.changedAt"
                :attributes="[{key: 'todayY', highlight: true, dates: new Date()}]"
              >
                <template v-slot="">
                  <v-col>
                    {{ formatDate(item.changedAt) }}
                  </v-col>
                  <v-col>
                  </v-col>
                </template>
              </vc-date-picker>
            </template>
            <template v-slot:item.executorBusinessName="{ item }">
              <template v-if="item.__typename === 'ProductPlanItemsCountLog'">
                {{ item.orderProductPlan.executor.businessName }}
              </template>
              <template v-else>
                {{ item.orderService.executor.businessName }}
              </template>
            </template>
          </v-data-table>
        </div>
        <!--SnackBar-->
        <v-snackbars
          :messages.sync="messages"
          color="red"
          timeout="3000"
          multi-line
          max-width="350"
          left
          rounded="lg"
          light
          elevation="4"
          content-class="snackbar"
        >
          <template v-slot:default="{ message }">
            <strong>{{ message.title }}</strong>
            <p>{{ message.text }}</p>
            <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
              <v-btn
                class="rounded-lg fz-12 fw-600 text-none border-none"
                outlined
                height="26"
                elevation="0"
                @click="doCopy('Error', message.text)"
              >Copy
              </v-btn>
            </v-row>
          </template>
        </v-snackbars>
        <!--  Loader  -->
        <div class="preloader" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import gql from 'graphql-tag'
import { statusColor } from '@/helpers/staticColors'
import { Locale } from 'v-calendar'
import VSnackbars from 'v-snackbars'

const locale = new Locale()

export default {
  name: 'SalaryStatusesHistory',
  components: {
    'v-snackbars': VSnackbars
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      messages: [],
      loading: false,
      headers: [
        {
          text: 'Item ID',
          value: 'itemId',
          filterable: false,
          sortable: false
        },
        {
          text: 'Product name',
          value: 'productPlanName',
          filterable: false,
          sortable: false
        },
        {
          text: 'Type',
          value: 'type',
          filterable: false,
          sortable: false
        },
        {
          text: 'Count',
          value: 'count',
          filterable: false,
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          filterable: false,
          sortable: false
        },
        {
          text: 'Income',
          value: 'income',
          filterable: false,
          sortable: false
        },
        {
          text: 'Changed date',
          value: 'changedAt',
          cellClass: 'pointer',
          filterable: false,
          sortable: false
        },
        {
          text: 'Executor',
          value: 'executorBusinessName',
          filterable: false,
          sortable: false
        }
      ],
      typeStatus: [
        {
          name: 'Backlog',
          status: 'BACKLOG'
        },
        {
          name: 'To do',
          status: 'TODO'
        },
        {
          name: 'In progress',
          status: 'IN_PROGRESS'
        },
        {
          name: 'Need Review',
          status: 'NEED_REVIEW'
        },
        {
          name: 'Review customer',
          status: 'REVIEW'
        },
        {
          name: 'Completed',
          status: 'COMPLETED'
        },
        {
          name: 'Pre sale',
          status: 'PRE_SALE'
        },
        {
          name: 'Canceled',
          status: 'CANCELED'
        }
      ],
      selectedCell: null,
      list: []
    }
  },
  computed: {
    dateForDatePicker() {
      return new Date()
    }
  },
  methods: {
    async getList() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getStatusesHistory($input: ProductPlanItemsCountInput, $input2: ServiceItemsCountInput) {
            salaryLog {
              salaryLogProductPlan(input: $input) {
                data {
                  __typename
                  id
                  changedAt
                  memorizedCount
                  status
                  incomeRub
                  orderProductPlan {
                    id
                    name
                    count
                    executor {
                    businessName
                    }
                    order {
                      id
                    }
                  }
                }
              }
              salaryLogService(input: $input2) {
                data {
                  __typename
                  id
                  changedAt
                  memorizedCount
                  status
                  incomeRub
                  orderService {
                    id
                    name
                    count
                    executor {
                    businessName
                    }
                    order {
                      id
                    }
                  }
                }
              }
            }
          }`,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            orderId: this.orderId,
            page: 1,
            pageSize: 999
          },
          input2: {
            orderId: this.orderId,
            page: 1,
            pageSize: 999
          }
        }
      }).then((data) => {
        this.list = []
        const logProductPlan = data.data.salaryLog.salaryLogProductPlan.data
        const logService = data.data.salaryLog.salaryLogService.data
        logProductPlan.forEach(el => {
          this.list.push(el)
        })
        logService.forEach(el => {
          this.list.push(el)
        })
        this.loading = false
      }).catch((err) => {
        console.log(err)
        this.loading = false
      })
    },
    async setNewDate(date) {
      this.loading = true
      if (this.selectedCell.__typename === 'ProductPlanItemsCountLog') {
        await this.$apollo.mutate({
          mutation: gql`mutation setNewDateProductPlan($input: UpdateProductPlanItemsCountLogInput!) {
          salaryLog {
            updateProductPlan(input: $input) {
              id
              changedAt
            }
          }
        }`,
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              id: this.selectedCell.id,
              changedAt: date
            }
          }
        }).then((data) => {
          this.addSnack('', 'Changes are successful', 'Date changed')
          this.loading = false
        }).catch((err) => {
          console.log(err)
          this.loading = false
        })
      } else {
        await this.$apollo.mutate({
          mutation: gql`mutation setNewDateService($input: UpdateServiceItemsCountLogInput!) {
          salaryLog {
            updateService(input: $input) {
              id
              changedAt
            }
          }
        }`,
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              id: this.selectedCell.id,
              changedAt: date
            }
          }
        }).then((data) => {
          this.addSnack('', 'Changes are successful', 'Date changed')
          this.loading = false
        }).catch((err) => {
          console.log(err)
          this.loading = false
        })
      }
    },
    getStatus(status) {
      for (const item of this.typeStatus) {
        if (item.status === status) return item.name
        if (status === null) return 'Null'
      }
    },
    changeDate(newDate) {
      const date = locale.format(newDate, 'YYYY-MM-DD')
      this.setNewDate(date)
    },
    color(value) {
      return statusColor(value)
    },
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    addSnack(item, result, text) {
      this.messages.push({ title: result, text: text })
    }
  },
  watch: {
    dialog: {
      handler(newValue) {
        if (newValue) this.getList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.salary-history {
  height: max-content;
}
</style>
