import Vue from 'vue'
import Vuex from 'vuex'
import { statusJustColor } from '@/helpers/staticColors'
import gql from 'graphql-tag'
import { createProvider } from '../vue-apollo'

let apollo = createProvider()
apollo = apollo.defaultClient

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    statusLabels: null,
    me: null,
    assignees: null,
    defaultFee: null,
    allRoleRates: null,
    teamRoleRates: null,
    allProductPlans: null,
    allProductPlansFormats: null,
    allServices: null,
    allDiscounts: null,
    allClientGenders: null,
    companyRoleList: null,
    allChannels: null,
    paymentAccounts: null,
    typesOfWork: null,
    skillLevels: null,
    niches: null,
    difficultyTypes: null,
    paymentTypes: null,
    allDesigners: null,
    countries: null,
    loading: false,
    snackbarMessage: []
  },
  getters: {
    getStatusLabels: (state) => {
      if (!state.statusLabels) return null
      const arr = state.statusLabels
      arr.forEach(el => {
        el.color = statusJustColor(el.value)
      })
      return state.statusLabels
    },
    getMe(state) {
      return state.me
    },
    getDefaultFee(state) {
      return state.defaultFee
    },
    getNiches: state => state.niches,
    getAllRoleRates(state) {
      return state.allRoleRates
    },
    getTeamRoleRates(state) {
      return state.teamRoleRates
    },
    getAllProductPlans(state) {
      return state.allProductPlans
    },
    getAllProductPlansFormats(state) {
      return state.allProductPlansFormats
    },
    getAllServices(state) {
      return state.allServices
    },
    getAllDiscounts: (state) => state.allDiscounts,
    getAllClientGenders(state) {
      return state.allClientGenders
    },
    getCompanyRoleList(state) {
      return state.companyRoleList
    },
    getAllChannels(state) {
      return state.allChannels
    },
    getPaymentAccounts(state) {
      return state.paymentAccounts
    },
    getTypesOfWork(state) {
      return state.typesOfWork
    },
    getSkillLevels(state) {
      return state.skillLevels
    },
    getDifficultyTypes: (state) => state.difficultyTypes,
    getAllDesigners: (state) => state.allDesigners.filter(el => !el.fired),
    getCountries: state => state.countries
  },
  mutations: {
    updatePaymentsAndDifficulties(state, payload) {
      state.difficultyTypes = payload.difficultyTypes
      state.paymentTypes = payload.paymentTypes
    },
    updateAllDesigners(state, payload) {
      state.allDesigners = payload
    },
    updateMe(state, me) {
      state.me = me
    },
    updateStatusLabels(state, statusLabels) {
      state.statusLabels = statusLabels
    },
    updateDefaultFee(state, defaultFee) {
      state.defaultFee = defaultFee
    },
    updateNiches(state, niches) {
      state.niches = niches
    },
    updateAllRoleRates(state, allRoleRates) {
      state.allRoleRates = allRoleRates
    },
    updateTeamRoleRates(state, teamRoleRates) {
      state.teamRoleRates = teamRoleRates
    },
    updateAllProductPlans(state, allProductPlans) {
      state.allProductPlans = allProductPlans
    },
    updateAllProductPlansFormats(state, allProductPlansFormats) {
      state.allProductPlansFormats = allProductPlansFormats
    },
    updateAllServices(state, allServices) {
      state.allServices = allServices
    },
    updateAllDiscounts(state, allDiscounts) {
      state.allDiscounts = allDiscounts
    },
    updateAllClientGenders(state, allClientGenders) {
      state.allClientGenders = allClientGenders
    },
    updateCompanyRoleList(state, companyRoleList) {
      state.companyRoleList = companyRoleList
    },
    updateAllChannels(state, allChannels) {
      state.allChannels = allChannels
    },
    updatePaymentAccounts(state, paymentAccounts) {
      state.paymentAccounts = paymentAccounts
    },
    updateTypesOfWork(state, typesOfWork) {
      state.typesOfWork = typesOfWork
    },
    updateSkillLevels(state, skillLevels) {
      state.skillLevels = skillLevels
    },
    updateGetCountries(state, payload) {
      state.countries = payload
    },
    updateLoading(state, payload) {
      state.loading = payload
    },
    showSnackbar(state, payload) {
      state.snackbarMessage = payload
    }
  },
  actions: {
    async fetchCountries({ commit }) {
      commit('updateLoading', true)
      apollo.query({
        query: gql`query getAllCountries {
            clientele {
              allCountries {
                code
                flagAbsoluteUrl
                id
                name
                clientsCount
                ordersCount
                ordersSum: ordersSumRub
              }
            }
          }`,
        fetchPolicy: 'no-cache'
      }).then(data => {
        commit('updateGetCountries', data.data.clientele.allCountries)
        commit('updateLoading', false)
      })
    },
    async fetchPaymentAccounts({ commit }) {
      commit('updateLoading', true)
      apollo.query({
        query: gql`query getPaymentAccounts {
          finance {
            accounts {
                type {
                  name
                  bankName
                  color
                  fontColor
                  id
                  logo
                }
                name
                balance
                description
                expirationDate
                lastFourDigits
                id
              }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then(data => {
        commit('updatePaymentAccounts', data.data.finance.accounts)
        commit('updateLoading', false)
      })
    }
  }
})
export const useStore = () => store
