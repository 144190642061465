<template>
  <v-col class="bg-white py-5" style="min-height: 100vh;">
    <!--Header-->
    <v-row class="mb-1 flex-grow-0">
      <v-col></v-col>
      <v-col class="py-0" align="center">
        <span class="fz-24 text-black fw-800">Salary</span>
      </v-col>
      <v-spacer></v-spacer>
      <!--Close Modal Button-->
      <v-btn
        width="40"
        height="40"
        icon
        elevation="0"
        class="transparent pa-0 mr-2"
        @click="$emit('closeSalaryDialog', 'text')"
      >
        <v-icon>$close</v-icon>
      </v-btn>
    </v-row>
    <!--Filters-->
    <v-row align="center" class="mt-0 mb-2 flex-grow-0">
      <v-col class="flex-grow-0">
        <v-select
          v-model="role.selected"
          :items="role.items"
          item-text="roleName"
          item-value="id"
          persistent-hint
          single-line
          dense
          outlined
          label="Role"
          style="min-width: 200px"
          height="40"
          class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
        >
          <!--      <template
                  v-slot:prepend-item
                >
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="selectAllUsers"
                  >
                    <v-list-item-action>
                      <v-icon v-if="role.toggle" style="width: 20px; height: 20px">
                        $checkboxIcon
                      </v-icon>
                      <v-icon v-else>
                        $checkboxOff
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>-->
          <template v-slot:selection="{ item, index }">
            <span v-if="index < 2">
              {{ item.roleName }}
            </span>
            <!--
            If Will be multiselect
            <span v-if="index < 1">,&nbsp;</span>
            <span
              v-if="index === 2"
              class="grey&#45;&#45;text"
            >
         &nbsp;and {{role.selected.length - 2}}
        </span>
            -->
          </template>
        </v-select>
      </v-col>
      <!--    <v-col>
       <v-select
         v-model="period.selected"
         :items="period.items"
         item-text="name"
         item-value="value"
         persistent-hint
         return-object
         single-line
         dense
         multiple
         outlined
         label="Period"
         height="40"
         class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
       >
        <template v-slot:selection="{ item, index }">
         <span v-if="index < 2">
          {{ item.name }}
         </span>
         <span v-if="index < 1">,&nbsp;</span>
         <span
           v-if="index === 2"
           class="grey&#45;&#45;text"
         >
           &nbsp;and {{period.selected.length - 2}}
          </span>
        </template>
       </v-select>
      </v-col>-->
      <v-col class="flex-grow-0">
        <div class="my-2 d-flex flex-nowrap"
             :class="{'mx-2': $vuetify.breakpoint.smAndUp,
              'mx-0': $vuetify.breakpoint.xsOnly,
               'flex-column': $vuetify.breakpoint.xsOnly,
                'align-start': $vuetify.breakpoint.xsOnly}">
          <v-btn-toggle
            class="rounded-lg box-shadow interval black--text hidden"
            style="padding-left: 2px; padding-right: 2px;"
            :class="{'mr-5': $vuetify.breakpoint.smAndUp}"
            background-color="#F2F4F5"
            v-model="interval"
            borderless
            dense
          >
            <v-btn
              class="rounded-lg my-auto fz-13 text-none"
              plain
              @click="getPrePrePreviouslyMonth"
              height="26"
            >
              {{ period.prePrePreviouslyMonth }}
            </v-btn>
            <v-btn
              class="rounded-lg my-auto fz-13 text-none"
              plain
              @click="getPrePreviouslyMonth"
              height="26"
            >
              {{ period.prePreviouslyMonth }}
            </v-btn>
            <v-btn
              class="rounded-lg my-auto fz-13 text-none"
              plain
              @click="getPreviouslyMonth"
              height="26"
            >
              {{ period.previouslyMonth }}
            </v-btn>
            <v-btn
              class="rounded-lg my-auto fz-13 text-none"
              plain
              @click="getCurrentMonth"
              height="26"
            >
              {{ period.currentMonth }}
            </v-btn>
            <v-btn
              class="rounded-lg my-auto fz-13 text-none"
              plain
              @click="getAllTime"
              height="26">
              All time
            </v-btn>
          </v-btn-toggle>
          <v-btn
            color="#010820"
            class="rounded-lg fz-13 fw-600 text-none"
            :class="{'mt-4': $vuetify.breakpoint.xsOnly}"
            height="40"
            @click="showDatepicker"
            outlined
          >
            {{ dateStart && dateEnd ? 'Period: ' + formatDate(dateStart) + ' - ' + formatDate(dateEnd) : 'Specify period' }}
            <div @click.stop v-if="interval !== 4">
              <v-icon size="16" v-if="dateStart && dateEnd" class="ml-2" @click="getAllTime">mdi-close</v-icon>
            </div>
          </v-btn>
        </div>
      </v-col>
      <v-spacer/>
    </v-row>
    <!--Body-->
    <v-expansion-panels
      v-model="accordion"
      multiple
      accordion
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="primary" class="path-black">
              $chevronDown
            </v-icon>
          </template>
          <p class="mb-0 fz-14 lh-22 font-weight-bold text-black">T&M</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-row class="mt-2 mb-2 pa-0 flex-nowrap">
            <SalaryTmTable
              :queryOption="{ datePeriod: { dateStart: this.dateStart, dateEnd: this.dateEnd }, roleId: role.selected}"
            />
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="primary" class="path-black">
              $chevronDown
            </v-icon>
          </template>
          <p class="mb-0 fz-14 lh-22 font-weight-bold text-black">Fix</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-row class="mt-2 mb-2 pa-0 flex-nowrap">
            <!--Fix Salary-->
            <v-col class="pt-0 pa-0 overflow-hidden">
              <SalaryFixTable
                :queryOption="{ datePeriod: { dateStart: this.dateStart, dateEnd: this.dateEnd }, roleId: role.selected}"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--Others-->
    <v-overlay
      :z-index="2"
      :value="overlay"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
    />
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-snackbars
      :messages.sync="messages"
      color="red"
      timeout="2000"
      multi-line
      max-width="350"
      left
      rounded="lg"
      light
      elevation="4"
      content-class="snackbar"
    >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <vc-date-picker
      class="inline-block h-full"
      locale="en"
      :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
      :popover="{ visibility: 'click' }"
      :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
      @popoverWillShow="overlay = true"
      @popoverDidHide="overlay = false"
      mode="range"
      is-range
      :masks="{input: 'YYYY-MM-DD', weekdays: 'WW'}"
      v-model="date">
      <template v-slot="">
        <button
          ref="datepickerShow"
          style="display: none"
        ></button>
      </template>
    </vc-date-picker>
  </v-col>
</template>

<script>
import SalaryTmTable from '@/components/App/Team/SalaryTmTable'
import SalaryFixTable from '@/components/App/Team/SalaryFixTable'
import gql from 'graphql-tag'
import { Locale } from 'v-calendar'
import VSnackbars from 'v-snackbars'
const locale = new Locale()

export default {
  name: 'SalaryExecutors',
  components: { 'v-snackbars': VSnackbars, SalaryTmTable, SalaryFixTable },
  // Delete
  data() {
    return {
      role: {
        selected: 'All',
        items: [],
        toggle: false
      },
      period: {
        selected: [],
        items: [
          {
            name: 'September',
            value: '2020-09-01'
          },
          {
            name: 'October',
            value: '2020-10-01'
          }
        ],
        prePrePreviouslyMonth: '',
        prePreviouslyMonth: '',
        previouslyMonth: '',
        currentMonth: ''
      },
      users: {
        selectedId: null,
        listOrders: []
      },
      orders: {
        headers: [
          {
            text: 'Number',
            value: 'number',
            align: 'center',
            filterable: true,
            sortable: false,
            width: 'min-width-100',
            class: 'px-0',
            cellClass: 'pointer'
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
            filterable: true,
            sortable: false,
            width: '150px',
            class: 'px-0',
            cellClass: 'pointer'
          },
          {
            text: 'Project',
            value: 'name',
            align: 'center',
            filterable: false,
            sortable: false,
            class: 'max-width-10p',
            cellClass: 'pointer'
          },
          {
            text: 'Date of creation',
            value: 'createdAt',
            align: 'center',
            filterable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Start date',
            value: 'dateStart',
            align: 'center',
            filterable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Update date',
            value: 'updatedAt',
            align: 'center',
            filterable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Expiration date',
            value: 'dateEnd',
            align: 'center',
            filterable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Client',
            value: 'client',
            align: 'center',
            sortable: false,
            width: '10%',
            cellClass: 'pointer'
          },
          {
            text: 'Assignee',
            value: 'assignee',
            align: 'center',
            sortable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Salary cost',
            value: 'salaryPayments',
            align: 'center',
            filterable: false,
            cellClass: 'pointer'
          },
          {
            text: 'Manager',
            value: 'manager',
            align: 'center',
            filterable: false,
            sortable: false,
            width: '10%',
            cellClass: 'pointer'
          }
        ],
        items: [],
        statusLabels: []
      },
      designers: {
        headers: [
          {
            text: 'Designer',
            value: 'name',
            align: 'center',
            cellClass: 'pointer'
          },
          {
            text: 'Salary',
            value: 'salary',
            align: 'center',
            cellClass: 'pointer'
          }
        ],
        items: []
      },
      dateStart: '',
      dateEnd: '',
      loading: false,
      overlay: false,
      interval: 4,
      accordion: [0],
      messages: []
    }
  },
  methods: {
    selectAllUsers() {
      if (this.role.selected.length === this.role.items.length) {
        this.role.toggle = false
        this.role.selected = []
      } else {
        this.role.toggle = true
        this.role.selected = this.role.items
      }
    },
    async getRoleExecutor() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getRoles {
          team {
            allRoles {
              id
              roleName
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then(data => {
        this.role.items = data.data.team.allRoles
        this.role.items.unshift({ id: 'All', roleName: 'All roles' })
        this.loading = false
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async getStatusLabels () {
      await this.$apollo.query({
        query: gql`query getStatusLabels {
          design {
            orders {
              orderStatusLabels {
                id
                label
                value
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.orders.statusLabels = data.data.design.orders.orderStatusLabels
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    getPrePrePreviouslyMonth() {
      const today = new Date()
      const start = new Date(today.getFullYear(), today.getMonth() - 3, 1, 0, 0, 1)
      const end = new Date(today.getFullYear(), today.getMonth() - 2, 0, 23, 59, 59)
      this.period.prePrePreviouslyMonth = locale.format(start, 'MMMM')
      this.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.dateEnd = locale.format(end, 'YYYY-MM-DD HH:mm:ss')
      const vm = this
      setTimeout(function () {
        vm.interval = 0
      }, 0)
    },
    getPrePreviouslyMonth() {
      const today = new Date()
      const start = new Date(today.getFullYear(), today.getMonth() - 2, 1, 0, 0, 1)
      const end = new Date(today.getFullYear(), today.getMonth() - 1, 0, 23, 59, 59)
      this.period.prePreviouslyMonth = locale.format(start, 'MMMM')
      this.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.dateEnd = locale.format(end, 'YYYY-MM-DD HH:mm:ss')
      const vm = this
      setTimeout(function () {
        vm.interval = 1
      }, 0)
    },
    getPreviouslyMonth() {
      const today = new Date()
      const start = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 1)
      const end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59)
      this.period.previouslyMonth = locale.format(start, 'MMMM')
      this.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.dateEnd = locale.format(end, 'YYYY-MM-DD HH:mm:ss')
      const vm = this
      setTimeout(function () {
        vm.interval = 2
      }, 0)
    },
    getCurrentMonth() {
      const start = new Date()
      const end = new Date()
      start.setDate(1)
      end.setHours(23)
      end.setMinutes(59)
      end.setSeconds(59)
      this.period.currentMonth = locale.format(start, 'MMMM')
      this.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.dateEnd = locale.format(end, 'YYYY-MM-DD HH:mm:ss')
      const vm = this
      setTimeout(function () {
        vm.interval = 3
      }, 0)
    },
    getAllTime () {
      this.dateStart = null
      this.dateEnd = null
      const vm = this
      setTimeout(function () {
        vm.interval = 4
      }, 0)
    },
    showDatepicker () {
      const vm = this
      setTimeout(function () {
        vm.$refs.datepickerShow.click()
      }, 0)
    },
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    }
  },
  computed: {
    date: {
      get () {
        const object = {
          end: this.dateEnd ? locale.parse(this.dateEnd, 'YYYY-MM-DD') : new Date(),
          start: this.dateStart ? locale.parse(this.dateStart, 'YYYY-MM-DD') : new Date()
        }
        return object
      },
      set (val) {
        console.log(val)
        val.end.setHours(23)
        val.end.setMinutes(59)
        val.end.setSeconds(59)
        this.interval = -1
        this.dateStart = val.start ? locale.format(val.start, 'YYYY-MM-DD HH:mm:ss') : ''
        this.dateEnd = val.end ? locale.format(val.end, 'YYYY-MM-DD HH:mm:ss') : ''
      }
    }
  },
  created() {
    this.getRoleExecutor()
    this.getStatusLabels()
    this.getPrePrePreviouslyMonth()
    this.getPrePreviouslyMonth()
    this.getPreviouslyMonth()
    this.getAllTime()
    this.getCurrentMonth()
  }
}
</script>

<style scoped lang="scss">
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background: rgba(0,0,0,.12)!important;
  color: rgba(0,0,0,.26)!important;
}
</style>
