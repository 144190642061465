<template>
    <v-col class="ma-0 px-0 pt-8 pb-0 d-flex flex-column height-100" @click.stop>
      <v-list class="pa-0">
        <v-list-item
          class="logo ma-0 bb-grey d-flex px-3 align-center justify-space-between"
          style="min-height: 36px"
        >
          <v-list-item-icon class="my-auto mr-0">
            <img v-if="!sidebarMini" src="@/assets/img/svg/logo.svg" alt="Logo">
            <img class="mt-2" v-else src="@/assets/img/svg/logo-mini.svg" alt="Logo">
          </v-list-item-icon>
          <v-list-item-content
            class="ml-auto pa-0"
            style="max-width: 36px; overflow: unset"
          >
            <v-app-bar-nav-icon v-if="!sidebarMini && $vuetify.breakpoint.mdAndUp" @click="$emit('hideSidebar')"></v-app-bar-nav-icon>
            <v-icon v-if="$vuetify.breakpoint.smAndDown" @click="$emit('hideSidebar')">mdi-close</v-icon>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        nav
        dense
        class="mt-10 flex-grow-1"
      >
        <v-list-item-group
          v-model="selectedItem"
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.url"
            class="rounded-lg"
          >
            <v-list-item-icon class="mr-3">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="white--text" v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/settings"
            v-if="$vuetify.breakpoint.smAndDown">
            <v-list-item-icon class="mr-3">
              <v-avatar :color="me.designerUser && me.designerUser.avatarUrl ? 'transparent' : 'primary'" size="24" class="pointer">
                <img
                  v-if="me.designerUser && me.designerUser.avatarUrl"
                  :alt="me.initials"
                  :src="me.designerUser.avatarUrl"
                >
                <span v-else class="fz-12 white--text">{{ me.initials }}</span>
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ me.fullName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item class="mt-5" v-if="$vuetify.breakpoint.smAndDown">
          <div v-if="currency" class="fz-14 font-weight-medium">
            <div class="mr-2 d-inline-flex justify-center align-center rounded-circle font-weight-bold currency-border"><v-icon class="currency-icon rounded-circle" x-small>$usd</v-icon> </div>
            <span class="white--text fz-14 font-weight-medium">{{ currency.title }}</span>
            <div class="currency-value text-black fz-14 font-weight-medium d-inline-flex justify-center align-center pa-1 rounded ml-1 mr-2">{{ currency.value }}</div>
            <small class="font-weight-bold" :class="{'white--text': currency.diff == 0, 'red-text': currency.diff < 0, 'green-text': currency.diff > 0}">{{ currency.diff }}</small>
          </div>
        </v-list-item>
      </v-list>
      <v-row class="ma-0 flex-grow-0 height-71">
        <v-btn
          text
          tile
          height="70"
          block
          @click="dialogShow"
          class="text-none fz-14 fw-600 bt-dgray"
        ><span v-if="!sidebarMini" class="white--text">Invite teammates</span><v-spacer></v-spacer> <v-icon class="rounded-lg size-24 bg-dgray" small>mdi-plus</v-icon></v-btn>
        <v-dialog
          transition="dialog-bottom-transition"
          content-class="rounded-4"
          max-width="560"
          v-model="dialogVisible"
          overlay-color="rgba(9, 24, 73, 0.2)"
          overlay-opacity="1"
        >
          <template v-slot:default="">
            <v-card class="rounded-4">
              <v-toolbar
                height="70"
                elevation="0"
                class="bb-gray"
              >
                <p class="fz-16 font-weight-bold text-black ff ma-0 ml-3 pa-0">Invite teammates</p>
                <v-spacer></v-spacer>
                <v-icon class="close-icon" @click="dialogHide">$close</v-icon>
              </v-toolbar>
              <v-card-text>
                <v-col class="px-1">
                  <v-form ref="form">
                    <p class="text-caption text-black font-weight-medium">Your teammates will get an email that gives them access to your team.</p>
                    <p class="fz-14 text-gray mb-1">
                      Gmail
                    </p>
                    <v-text-field
                      dense
                      v-model="gmail"
                      outlined
                      required
                      class="mb-0 fz-14 text-black rounded-lg input-border"
                      :rules="[rules.email]"
                    ></v-text-field>
                    <p class="fz-14 text-gray mb-1">
                      Role
                    </p>
<!--                    <v-autocomplete-->
<!--                      dense-->
<!--                      :items="role"-->
<!--                      v-model="roleSelected"-->
<!--                      outlined-->
<!--                      required-->
<!--                      item-color="#010820"-->
<!--                      item-text="roleName"-->
<!--                      item-value="id"-->
<!--                      hide-selected-->
<!--                      :rules="[rules.required]"-->
<!--                      class="mb-3 fz-14 text-black select-dash rounded-lg input-border"-->
<!--                      >-->
<!--                    </v-autocomplete>-->
                    <v-menu nudge-bottom="50" content-class="menu-shadow rounded-lg bg-white border-lightgray">
                      <template v-slot:activator="{ on, attrs }">
                        <v-col class="pa-0">
                          <v-text-field
                            outlined
                            single-line
                            :value="roleSelected ? roleSelected.roleName : ''"
                            class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
                            dense
                            readonly
                            :rules="[rules.required]"
                          >
                            <template v-slot:prepend-inner>
                               <v-sheet
                                v-bind="attrs"
                                v-on="on"
                                class="absolute"
                                elevation="0"
                                color="transparent"
                                style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                               ></v-sheet>
                             </template>
                             <template v-slot:append>
                               <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                             </template>
                          </v-text-field>
                        </v-col>
                      </template>
                      <v-card class="rounded-lg mt-1 hidden" elevation="0">
                        <v-card-text class="px-0 py-1">
                          <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                            <v-list-item
                              v-for="(item, index) in role"
                              :key="index"
                              @click="roleSelected = item"
                              style="min-height: 30px"
                              class="px-0 py-1"
                            >
                              <p class="ma-0 px-2 fz-12 text-black">{{ item.roleName }}</p>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                    <v-row class="ma-0 pa-0">
                      <v-spacer></v-spacer>
                      <v-btn
                        elevation="0"
                        color="primary"
                        class="rounded-lg fz-16 fw-600 text-none ml-auto"
                        width="155"
                        @click="invite"
                      >Send</v-btn>
                    </v-row>
                  </v-form>
                </v-col>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>
      </v-row>
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
    </v-col>
</template>

<script>
import gql from 'graphql-tag'
import VSnackbars from 'v-snackbars'

export default {
  props: ['sidebarMini', 'currency', 'me'],
  components: { 'v-snackbars': VSnackbars },
  data: () => ({
    selectedItem: 0,
    messages: [],
    items: [
      { text: 'Plans & Pricing', icon: '$plans', url: '/plans' },
      { text: 'Orders', icon: '$orders', url: '/orders' },
      { text: 'Team', icon: '$team', url: '/team' },
      { text: 'Finance', icon: '$finance', url: '/finance' },
      { text: 'Clients', icon: '$clients', url: '/clients' },
      { text: 'Designer Account', icon: '$designer', url: '/account' },
      { text: 'Tools', icon: '$tools', url: '/tools' },
      { text: 'Niches', icon: '$niches', url: '/niches' },
      { text: 'Kanban Orders', icon: '$kanban', url: '/kanban' },
      { text: 'Domains', icon: '$domain', url: '/domains' }
    ],
    dialogVisible: false,
    gmail: null,
    role: [],
    roleSelected: null,
    rules: {
      required: value => (value !== null && String(value).length > 0) || 'Required.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      }
    }
  }),
  methods: {
    async getAllRoleRates () {
      await this.$apollo.query({
        query: gql`query TeamRoleRates {
          pricing {
            allRoleRates {
              roleName
              id
            }
          }
        }`
      }).then((data) => {
        this.role = data.data.pricing.allRoleRates
        this.$store.commit('updateAllRoleRates', data.data.pricing.allRoleRates)
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    invite () {
      if (this.$refs.form.validate()) this.sendInvite()
    },
    async sendInvite () {
      await this.$apollo.mutate({
        mutation: gql`mutation SendInvite($input: InviteDesignerInput!) {
          design {
            team {
              invite(input: $input)
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            gmail: this.gmail,
            roleId: this.roleSelected.id
          }
        }
      }).then(() => {
        this.addSnack(this.gmail, 'Success', 'Invite mail was sent successfully to: ' + this.gmail)
        this.dialogHide()
        this.gmail = null
        this.roleSelected = null
      }).catch((err) => {
        console.log(err)
      })
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    },
    dialogShow () {
      this.dialogVisible = true
    },
    dialogHide () {
      this.dialogVisible = false
    }
  },
  created () {
    this.$store.getters.getAllRoleRates ? this.role = this.$store.getters.getAllRoleRates : this.getAllRoleRates()
  }
}
</script>

<style scoped lang="scss">
  * {
      font-family: inter, sans-serif;
    }
  .design-logo {
    width: 120px;
    height: 30px;
    position: absolute;
    right: 120px;
  }
  .row-relative {
    position: relative;
  }
  .v-list--nav .v-list-item, .v-list--nav .v-list-item:before {
    border-radius: 8px !important;
  }
</style>
